@media only screen and (max-width: 532px){
    .GalleryHeading h1{
        font-size: 1.65rem;

    }
}

.Image_gallery:has(img:hover) img:not(:hover){
    scale: 0.9;
    opacity: .7;
}
.Image_gallery:has(img:hover) img{
    scale: 1.1;
}