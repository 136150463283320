.HomeMainContainer{
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    height: 90svh;
    position: relative;
    overflow-x: hidden;
    background: linear-gradient(#f5f7fa ,#c3cfe2);
}
.HomeInfo{  
    width: 50rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 11;
    
}
.HamroH1{
    font-size: 3rem;
    color: #000000;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.HamroH2{
    font-family: 'helvetica';
    font-weight: 500;
    font-size: 1.3rem;
    margin-left: .8rem;
    color: #3d3d3d;
}
.H4{
    margin-bottom: -1rem;
    color: var(--primary-red);
    animation: lightSpeedInRight 1s linear forwards;
}
.H3{
    margin-top: -1rem;
    color: var(--primary-blue);
    animation: fadeInUp 1s linear forwards;
    
}
.HomePageBtn{
    margin-top: 1rem;
    width: 70%;
    height: 100px;
    cursor: pointer;
}
.HomeBtn a{
    text-decoration: none;
    color: #333;
    position: relative;
    height: 100%;
    width: 100%;display: flex;
    align-items: center;
    justify-content: center
}
.HomeBtn{
    margin-left: -1rem;
    height: 50%;
    width: 120px;
    border: 1.3px solid #000000;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: all 0.3s ease;
    box-shadow: 0px 3px 0px 0px #333333af;
}
.HomeBtn:hover {
    scale: 1.1;
    border: none;
    box-shadow: 0px 7px 0px 0px #0a06dec2;
    background-color: var(--primary-blue);
}
.HomeBtn .Text{
    font-size: 1.1rem;
    font-family: sans-serif;
    visibility: visible;
    transition: all 0.1s ease-in-out;
}
.HomeBtn .TIcon{
    position: absolute;
    transform: translateY(-30%);
    font-size: 1.5rem;
    scale: 0;
    transition: all 0.4s ease-in-out;
}
.HomeBtn:hover .TIcon{
    color: #eee;
    scale: 1.1;
    animation: bounce 2s linear forwards;
}
.HomeBtn:hover .Text{
    visibility: hidden;
}
.HomeLottieSvg{
    overflow: hidden;
    height: 100%;
    right: 0;
    top: 0;
    position: absolute;
    width: 70rem;
    z-index: 10;
}
.LottieHolder{
    height: 100%;
    width: 100%;
}
.LottieHomePage{
    width: 100%;
    height: 100%;
}


@media only screen and (width < 1490px){
.HomeLottieSvg{
    right: -10%;
}
}
@media only screen and (max-width: 820px){
.HomeLottieSvg{
   width: 60rem;
}
}


@media only screen and (max-width: 670px){
 
.HamroH1{
    font-size: 2.1rem;
}
.InFodetails{
    margin-left: -3rem;
}
.HomeBtn{
    margin-left: -3rem;
}
    }

@media only screen and (width < 480px){
    .InFodetails{
        margin-left: 1rem;
    }
    .HamroH1{
    font-size: 1.65rem;
    color: #fff;
    }
    .H3{
        color: var(--primary-aqua);
    }
.HomeBtn{
    width: 110px;
    margin-left: 0rem;
    backdrop-filter: blur(2px);
}
.HamroH2{
    font-size: 1.1rem;
    }
    .HomeMainContainer{
        background:none;
    }
    .HomeLottieSvg{
    right: -33%;
        height: 90%;
        width: 75rem;
     }
     .InFodetails{
        margin-top: -3rem;
     }
}
