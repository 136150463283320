.FooterContainer{
    margin-top: 5%;
    /* height: 30vh; */
    width: 100%;
    background: linear-gradient(to right , #020838,#160A1E,#2C0B02);
    border-top-left-radius: 7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    
}
.footTop{
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.footTop h1{
    /* font-family: 'helvetica'; */
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 1.8rem;
    color: #eee;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
}

.First {color: #eee;
    transition: all 0.4s ease-in-out;
}
.First span{color: var(--primary-pink);
    transition: all 0.4s ease-in-out;
}
.second {color: #eee;}
.second span{color: var(--primary-red);
    transition: all 0.4s ease-in-out;
}
.third {color: #eee;
    transition: all 0.4s ease-in-out;
}
.third span{color: var(--primary-aqua);
    transition: all 0.4s ease-in-out;
}
.fourth {color: #eee; 
    transition: all 0.4s ease-in-out;
}
.fourth span{color: var(--primary-lime);
    transition: all 0.4s ease-in-out;
}

.footTop h1:hover{
    scale: 1.09;
}
.footTop h1:hover .First{color: var(--primary-pink);}
.footTop h1:hover .second{ color: var(--primary-red);}
.footTop h1:hover .third{color: var(--primary-aqua);}
.footTop h1:hover .fourth{color: var(--primary-lime);}

.footTop h1:hover .First span{color: #eee;}
.footTop h1:hover .second span{ color: #eee;}
.footTop h1:hover .third span{color: #eee;}
.footTop h1:hover .fourth span{color:#eee;}

.GoTop{
    height: 32px;
    width: 18px;
    color: #eee;
    border: 1px solid #eee;
    border-radius: 1rem;
    position: relative;
    cursor: pointer;
    animation: heartBeat 1s linear infinite;
}
.GoDot{
    font-size: 0.7rem;
    position: absolute;
    left: 55%;
    transform: translateX(-50%);
    top: 16%;
    animation: topDown 2s linear infinite;
}
@keyframes topDown {
    0%{
        top: 16%;
    }50%{
        top: 48%;
    }100%{
        top: 16%;
    }

}



.footMiddle{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-bottom: 5%;
}

.FootInfo{
    text-align: left;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    color: #eee;
    position: relative;
}
.FootInfo h3{
    font-family: 'helvetica';
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.2rem;
}
.FootSocial h3{
    font-family: 'helvetica';
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.2rem;
}
.FootInfo p{
    margin-left: 10px;font-weight: 400;
    font-family: sans-serif;
    font-size: 1rem;
}
.addBox{
    width: 100%;
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;
   
}
.addHolder{
    display: flex;
    align-items: left;
    justify-content: left;
    margin-top: 10px;
}
.FootIcon{
    font-size: 1.2rem;
    padding: 14px 16px;
    background-color: #eee;
    border-radius: 50%;
    color: #333333;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
}
.FootIcon:hover{
    background-color: #333333;
    color: #eee;
}
.addHolder p{
   margin-left: 10px;font-weight: 400;
    font-family: sans-serif;
    /* font-size: 0.9rem; */
}
/* .SocialIcon{
    font-size: 1.3rem;
} */
.FootSocial {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    color: #eee;
    position: relative;
}

.footDown{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: fit-content;

}
.footDown .horiLine{
    margin-top: 1%;
    width: 85%;
    height: 2px;
    background-color: #eee;
    border-radius: 5px;
}
.footDown .FootText{
    text-align: center;
    color: #eee;
    font-family: 'helvetica';
    
}
.footDown .FootText p{
    font-weight: 400;
    font-size: 0.9rem;
}
.footDown .FootText p a{
    text-decoration: none;
    color: #eee;
    font-style: italic;
    position: relative;
}
.footDown .FootText p a span{
    font-style: none;
    font-weight: 500;
    transition: all 0.4s ease-in-out;


}
.footDown .FootText p a::before {
    content: '';
    position: absolute;
    height: 2px;
    width: 0;
    bottom: -1px;
    right: 0;
    border-radius: 10px;
    background-color: var(--primary-lime);
    transition: all 0.4s ease-in-out;
}
.footDown .FootText p a:hover span{
    color: var(--primary-aqua);

}
.footDown .FootText p a:hover::before {
    width: 80%;
}

@media only screen and (max-width:670px){

    .footTop h1{
        font-size: 1.5rem;
        margin-left: 5%;
    }
    .FooterContainer{
        border-top-left-radius: 5rem;
    }
}

@media only screen and (max-width:532px){

    .addInfo{
        margin-left: 2%;
    }
    .SocialBox{
        flex-direction: row;
        
    }.SocialBox .addHolder{
        margin: 5%;
    }
.FootInfo p{
    font-size: 0.86rem;
}
.FootInfo{
    margin-left:3.5%;
}
}

@media only screen and (max-width:440px){
    .FooterContainer{
        border-top-left-radius: 3.8rem;
    }
    .footTop h1{
        font-size: 1.3rem;
        margin-left: 5%;
    }
    .FootInfo p{
        font-size: 0.78rem;
        /* padding: 10px; */
        
    }
.FootInfo h3{
    font-size: 1.1rem;
}
}