.AboutContainer{
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap ;
    margin-bottom: 5%;

}
.aboutAnimation{
    border-radius: 1rem;
    width: 37rem;
    display: flex;
    align-items: center;
    justify-content: center;

}
.aboutAnimation img{
    width: 100%;
    margin-top: 3rem;
    border-radius: 1rem;
    object-fit: cover;
}
.AboutusLottie{
    height: 100%;
    width: 100%;
}

.aboutInfo{
    margin-top: 2rem;
    width: 52rem;
    /* height: 60vh; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.InfoUS{
    width: 88%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
   background-color: var(--background-color);
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;


}
.infoContainer p{
    text-align: center;
    font-size: 1rem;
    padding: 10px 12px; 
    margin-bottom: 3%;

}
.aboutH1{
    font-size: 1.8rem;
    font-family: 'helvetica';
    font-weight: 450;
}
.infoContainer{
    width: 80%;
}
.ButtonsAbout{
    display: flex;
    gap: 1rem;
    margin-bottom: 5%;
}
.aboutBtn{
    height: 48px;
    width: 120px;
    background-color: var(--primary-blue);
    display: flex;
    border-radius: 0.7rem;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}
.aboutBtn1{
    border-radius: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: #eee;
    transition: all .4s ease-in-out;
    font-size: 1.1rem;
}
.aboutBtn1 a{
    transition: all .4s ease-in-out;
    
}
.aboutFontAw{
    transition: all .4s ease-in-out;
    display: none;
}
.aboutBtn1:hover .aboutFontAw{
    scale: 1.1;
    display: block;
}
.aboutBtn1:hover a{
    display: none;
}


@media only screen and (max-width: 600px){
.InfoUS{
    width: 92%;
}
.infoContainer p{
    font-size: 0.87rem;
    padding: 1px; 
    margin-bottom: 1.5rem;

}
.ButtonsAbout{
    margin-bottom: 9%;
}
.aboutH1{
    font-size: 1.65rem;
}
.aboutAnimation{
    margin-top: -1rem;
    margin-bottom: 2.2rem;
}
.aboutAnimation img{
    width: 90%;
}
}