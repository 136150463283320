
.h12{
    color: #fff;
    font-size: 8rem;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;
}
        /* &::before{
            content: '<h1>';
            font-family: 'La Belle Aurore';
            color: aqua;
            font-size: 18px;
            position: absolute;
            margin-top: -32px;
            left: 20px;
            opacity: 0.6;
            animation: fadeInUp 1s 1s forwards;

        }
            &::after{
                content: '</h1>';
                font-family: "La Belle Aurore";
                color: aqua;
                font-size: 18px;
                position: absolute;
                margin-top: 24px;
                margin-left: 20px;
                animation: fadeInRight 1s 3s backwards;
                opacity: 0.6;
            } */

.PrgmWrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.box{
 
    position: relative;
    height: 350px;
    width: 360px;
    position: relative;
    overflow: hidden;
    border-radius: 1rem;
    margin: 1.2rem;
    margin-bottom: 2.5rem;
    margin-top: 2.5rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    cursor: pointer;
    animation: fadeInUp 0.7s linear forwards;
    opacity: 0;
}
.box:nth-child(1){animation-delay: 1.2s;}
.box:nth-child(2){animation-delay: 1.4s;}
.box:nth-child(3){animation-delay: 1.6s;}
.box:nth-child(4){animation-delay: 1.8s;}
.box:nth-child(5){animation-delay: 2s;}
.box:nth-child(6){animation-delay: 2.2s;}
.box:nth-child(7){animation-delay: 2.4s;}
.box:nth-child(8){animation-delay: 2.6s;}
.box:nth-child(9){animation-delay: 2.8s;}
.box:nth-child(10){animation-delay: 3s;}
.box:nth-child(11){animation-delay: 3.2s;}
.box:nth-child(12){animation-delay: 3.4s;}
.box:nth-child(13){animation-delay: 3.5s;}
.box:nth-child(14){animation-delay: 3.6s;}
.box:nth-child(15){animation-delay: 3.7s;}
.box:nth-child(16){animation-delay: 3.8s;}
.box:nth-child(17){animation-delay: 3.9s;}
.box:nth-child(18){animation-delay: 4s;}
.box:nth-child(19){animation-delay: 4.1s;}
.box:nth-child(20){animation-delay: 4.2s;}
.box:nth-child(21){animation-delay: 4.3s;}
.box:nth-child(22){animation-delay: 4.4s;}
.box:nth-child(23){animation-delay: 4.5s;}
.box:nth-child(24){animation-delay: 4.6s;}
.box img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;

}
.infoBox{
    width: 100%;
    position: absolute;
    bottom: -16%;
    background-color: rgba(0, 0, 0, 0.41);
    color: #fefefe;
    transition: all 0.3s ease;
    

}
.box:hover .infoBox {
    bottom: 0;
}
.prgm-name h2{
    font-size: 1.25rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin-left: 15px;
   
}
.BtnCon{
    margin-left: 15px;
    /* margin-bottom: 5px; */
}
.action-btns{
    display: inline-block;
    padding: 11px 18px;
    background-color: rgb(17, 17, 227);
    border: 2px solid rgb(17, 17, 227);
    margin: 5px;
    /* margin-left: 15px; */
    border-radius: 0.5rem;
    text-decoration: none;
    font-size: 1rem;
    font-family: sans-serif;
    color: #eee;
    cursor: pointer;
    
    
}
.dark{
    background-color: transparent;
    color: #eee;
}

.PopBox{
    top: 0;
    bottom: 0;
    right: 0;left: 0;
    height: 100vh;
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eeeeee2b;
    backdrop-filter: blur(5px);
}
.PopInfoBox{
    height: 540px;
    width: 520px;
    border-radius: 0.9rem;
    position: relative;
    box-shadow: rgba(50, 50, 93, 0.8) 0px 50px 100px -20px, rgba(0, 0, 0, 0.5) 0px 30px 60px -30px;
    min-height: 420px;
}
.PopInfoBox img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 0.9rem;
    position: absolute;
    z-index: -1;
}
.PopInfoBox .PopDetail{
    position: absolute;
    bottom: 0;
    color: #eee;
    /* padding: 1rem 1.2rem; */
    /* height: 100%; */
    width: 100%;
    text-align: center;
    background-color: #333333aa;
    z-index: 10;
    border-bottom-left-radius: 0.9rem;
    border-bottom-right-radius: 0.9rem;
}
.PopDetail h2{
    font-size: 1.16rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 600;
}
.PopDetail p{
    font-size: 0.96rem;
    font-family: sans-serif;
    padding: 10px 14px;
}
.PopBtn .action-btn{
    display: inline-block;
    padding: 11px 18px;
    background-color: rgb(17, 17, 227);
    border: 2px solid rgb(17, 17, 227);
    margin: 5px;
    border-radius: 0.5rem;
    text-decoration: none;
    font-size: 1rem;
    font-family: sans-serif;
    color: #eee;
    cursor: pointer;
    z-index: 99999;
}
.Xmark{
    font-size: 1.5rem;
    position: absolute;
    right: 3%;
    top: 2%;
    background-color: #333333c2;
    color: #eee;
    padding: 10px 13px;
    border-radius: 50%;
    cursor: pointer;
}

@media only screen and (max-width : 500px){
    .PopInfoBox{
    height: 500px;
    width: 370px;
    }

}