.Headings{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 1%;
    position: relative;
}
.Headings h1{
    font-size: 2.4rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin-top: 3%;
    margin-bottom: 0;
    /* letter-spacing: 2px; */
    cursor: pointer;
    font-weight: 600;
    color: #333;
    position: relative;
}

.Headings h4{ 
    text-align: center;
    font-size: 1rem;
    font-family: 'helvetica';
    font-weight: 500;
    animation: flipInY 4s linear forwards;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    position: relative;
}
.Headings h4::before{
    content: '';
    height: 2px;
    width: 16px;
    position: absolute;
    left: -5%;
    top: 50%;
    background-color: red;
}
.Headings h4::after{
    content: '';
    height: 2px;
    width: 16px;
    position: absolute;
    right: -5%;
    top: 50%;
    background-color: red;
}
.Headings h4:hover{
    scale: 1.06;
}

.Headings h4 a{
    text-decoration: none;
    color: #303030;
    font-weight: 500;
    font-style: italic;
    transition: all 0.3s ease-in-out;

}
.Headings h4:hover a{
    color: var(--primary-red);
    
}
.Headings h4 a .EmailIcon{
    color: var(--primary-red);
}
@media only screen and (max-width : 500px){

.Headings h4{
font-size: 0.8rem;
}   
}