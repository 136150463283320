

.TenureWrapper{
  margin-top: 2%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 8%;
}

.TeamCardWrapper{
  width: 320px;
  height: 380px;
  background: none;
  margin: 3%;
  /* top: 50%;
  left: 50%; */
  
}

.TeamCard{
    position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transform-style: preserve-3d;
  transition: all 0.8s ease;
  cursor: pointer;
}


.TeamCardWrapper:hover .TeamCard{
    transform: rotateY(180deg);
  }

  .TeamCardFront{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    backface-visibility: hidden;
    object-fit: cover;
    overflow: hidden;
    color: #000;
  }
.TeamCardFront img{
  height: 100%;
  width: 100%;
    border-radius: 10px;
    object-fit: cover;
}
  .TeamCardBack{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    backface-visibility: hidden;
    overflow: hidden;
    color: #333;
    text-align: center;
    transform: rotateY(180deg);
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    /* box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px; */
    
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;background-color: var(--background-color);

  }
.TeamCardBack img{
  height: 120px;
  width: 120px;
  object-fit: cover;
  border-radius: 10px;
  border-radius: 50%;box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.TeamCardBack .information{
  text-align: center;
  font-family: 'helvetica';
}.TeamCardBack .information h2{
  color: var(--primary-red);
  text-shadow: 2px 7px 5px rgba(0,0,0,0.3), 
    0px -4px 10px rgba(255,255,255,0.3);
}.TeamCardBack .information h3{
  text-align: center;
  color:#000;
  font-size: 1rem;
  font-weight: 500;
  margin-top: -6px;
}
.TeamCardBack .links{
  margin-top: 1rem;
  width: 80%;
}
.TeamCardBack .links a{
  text-decoration: none;
}
.TeamCardBack .links .Tlinks{
  margin: 6px;
  font-size: 1.2rem;
  transition: all 0.3s ease-in-out;
}
.Tlinks:hover{scale: 1.09;}
.links a:nth-child(1){color: rgb(32, 95, 243);}
.links a:nth-child(2){color:#c92bb7 ;}
.links a:nth-child(3){color: rgb(32, 95, 243);}
.links a:nth-child(4){color: rgb(243, 32, 32);}
.links a:nth-child(5){color: rgb(208, 2, 235);}

  .filterWrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 2.4%;
  }

  /* CSS */
  .filterBtn {
    align-items: center;
    appearance: none;
    background-color: #FCFCFD;
    border-radius: 4px;
    border-width: 0;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset;
    box-sizing: border-box;
    color: #36395A;
    cursor: pointer;
    display: inline-flex;
    font-family: 'helvetica';
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s,transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow,transform;
    font-size: 18px;
    margin-right: 14px;
    margin-bottom: 8px;
  }
  
  .filterBtn:focus {
    box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  }
  
  .filterBtn:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
    transform: translateY(-2px);
  }
  
  .filterBtn:active {
    box-shadow: #D6D6E7 0 3px 7px inset;
    transform: translateY(2px);
  }   
  .activeBtn{
    align-items: center;
    appearance: none;
    background-color: #FCFCFD;
    border-radius: 4px;
    border-width: 0;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset;
    box-sizing: border-box;
    color: #36395A;
    cursor: pointer;
    display: inline-flex;
    font-family: 'helvetica';
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s,transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow,transform;
    font-size: 18px;
    margin-right: 14px;
    margin-bottom: 8px;box-shadow: #D6D6E7 0 3px 7px inset;
    transform: translateY(2px);
  }
  .activeBtn:focus {
    box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  }
  
  .activeBtn:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
    transform: translateY(-2px);
  }
  