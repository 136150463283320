.TestiHeading::after{
    content: '';
    position: absolute;
    height: 6px;
    width: 8rem;
    background-color: var(--primary-red);
    bottom: -20%;
    border-radius: 1rem;
}
.TestiHeading::before{
    content: '" What people say about us  "';
    position: absolute;
    font-size: 0.96rem;
    color: var(--primary-red);
    top: 1%;
}
.TestiContainer{
  overflow: hidden;
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 6%;
}
.TestiSwiper {
    width: 380px;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  
  .MySwiperCards{
    border-radius: 1.6rem;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    flex-direction: column;
    background-color: var(--background-color);

  }
  .TestiImage{
    height: 22%;
    width: 100%;
    background-color: rgb(0, 140, 255);
   position: relative;

  }
  .TestiImage img{
    position: absolute;
    left: 50%;
    top: 90%;
    transform: translate(-50%,-50%);
    height: 130px;
    width: 130px;
    border-radius:50%;
    object-fit: cover;
    transition: all 0.1s ease-in-out;
  }
  .TestiImage img:active{
    scale: 1.05;
    cursor: grabbing;
  }
  .TestiImage img:hover{
    top: 82%;
  }
  .TestiDetails{
    margin-top: 15%;
    height: 50%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

  }
  .TestiDetails div{
    text-align: center;
  }
  .TestiDetails div h3{
    font-size: 1.1rem;
  }
  .TestiDetails div h2{
    margin-top: -11px;
    height: 1.2rem;
  }
  .TestiDetails div h2,h3{
    font-weight: 500;
    font-family: 'helvetica';
  }
  .TestiDetails p{
    text-align: center;
    margin-top: -0.1rem;
    font-size: 0.9rem;
    padding: 10px 12px;
    margin-bottom: 2%;
  }
  .TestiSocial{
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 10%;
    justify-content: center;
}
.TestiSocial a{
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: var(--primary-blue);
    font-size: 1rem;
}
.TestiSocial a .IconBro{
    font-size: 1.4rem;
    color: var(--primary-blue);
    text-decoration: none;
    margin-left: 5px;
    padding: 0.8rem 0.8rem;
    border-radius: 50%;
    transition: all 0.3s ease;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}
  .TestiSocial a:hover .IconBro{
    scale: 1.09;
  }

  @media only screen and (max-width:550px){
    .TestiSwiper{
        width: 310px;
        height: 540px;
    }
    .TestiHeading::before{
        top: -10%;
    }
    .TestiContainer{
        margin-top: 12%;
        margin-bottom: 8%;
    }
    .TestiDetails p{
        padding: 10px 14px;
        font-size: 0.86rem;
    }
  .TestiSocial{
    margin-top: 8%;

  }.TestiSocial a{
    font-size: 0.92rem;
  }
.TestiSocial a .IconBro{
    font-size: 1.2rem;
    padding: 0.72rem 0.72rem;
}
  }