.contactPageMain{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;

}
.contactForm{
    width: 50rem;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    animation: zoomIn 0.6s linear forwards;
}
.contactForm form h1{
    font-size: 2rem;
    font-family: 'helvetica';
    font-weight: 500;
}
.contactForm form{
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--background-color);
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

}
.FormItems{
    width: 80%;
    justify-content: space-around;
    display: flex;
    margin-top: 12px;
}
.FormItems input,
.FormItems textarea{
    width: 80%;
    padding: 1rem 1rem;
    border-radius: 1rem;
    border: none;
    font-family: 'helvetica';
    font-weight: 400;
    color: #333333;
    background-color: rgb(246, 246, 251);
    transition: all 0.3s ease-in-out;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}
.FormItems input:focus,
.FormItems textarea:focus{
    scale:1.04;
    outline: none !important;
    border-bottom: 1px solid var(--primary-blue);
}
.contactImage{
    height: fit-content;
    width: 38rem;
    /* background-color: green; */
}
.FormButton{
    margin-right: 10%;
    justify-content: right;
}
.FormButton button{
    margin-bottom: 1rem;
    padding: .7rem 1.5rem;
    border-radius: 10px;
    font-size: 1.05rem;
    cursor: pointer;
    font-family: 'helvetica';
    border: 2px solid var(--primary-blue);
    position: relative;
    color: #eee;
    background-color: var(--primary-blue);
    transition: all 0.4s ease-in-out;
    transition-delay: 0.2s;
}
.FormButton button .SubmitPlane{
    font-size: 1.1rem;
    transition: all 0.3s ease-in-out;
}

.FormButton button:hover .SubmitPlane{
   scale: 1.3;
}
.confirmBox{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #eeeeee;
    height: 380px;
    width: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 1rem;
    z-index: 999;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
    animation: zoomIn 0.2s linear forwards;
}
.confirmSvg{
    height: 50%;
    width: 80%;
    position: relative;
}
.confirmSvg .lottieSvg{
    height: 100%;
}
.closeBtnForm{
    position: absolute;
    top: 3%;
    right: 3%;
    padding: 0.8rem 1rem;
    border-radius: 50%;
    font-size: 1.5rem;
    /* color: #eee; */
    background-color: #eee;
    cursor: pointer;
}
.confirmBox p{
    padding: 2rem;
    text-align: center;
    font-family: 'helvetica';
    font-size: 0.9rem;
}
@media only screen and (max-width: 532px){

.contactForm form{
    width: 90%;
}
.FormItems input,
.FormItems textarea{
    width: 94%;
}
.contactForm form h1{
    font-size: 1.5rem;
}
}