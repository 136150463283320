.loader{
    position: fixed;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f0f0f0;
    backdrop-filter: blur(10px);
    z-index: 99999;
    top: 0;
}

.loaderImg img{
width: 90px;
height: 90px;
object-fit: cover;
border-radius: 50%;
}
.loadingBar-text{
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
}
.loadingBar-text h2{
font-size: 1.1rem;
font-family: Verdana, Geneva, Tahoma, sans-serif;
font-weight: 500;
}
.Loadingbar{
width: 300px;
height: 5px;
margin-top: 10px;
background-color: black;
border-radius: 10px;
position: relative;
}
.Loadingbar .fillBar{
position: absolute;
height: 100%;
background-color: blue;
border-radius: 10px;
animation: fillBar 0.6s linear forwards;
}
@keyframes fillBar {
0%{
    width: 0;
}50%{
    width: 45%;
}100%{
    width: 100%;
}
}
