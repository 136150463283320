@import 'animate.css';


.loader-hidden {
    display: none;
  }
  
  .loader-active {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    width: 50px;
    height: 50px;
    animation: zoomOut 1s 1s;
    animation-fill-mode: forwards;
  }
  @font-face {
    font-family: 'Coolvetica';
    src: local(''),
        url('./assests/fonts/CoolveticaRg-Regular.woff') format('woff2');
  } 
  
  @font-face {
    font-family: 'helvetica';
    src: local(''),
        url('./assests/fonts/helvetica-neu.ttf') format('ttf');
  } 
  