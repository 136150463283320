.text-animate{
    display: inline-block;
    opacity: 0;
    animation: bounceIn 1s 1s;
    animation-fill-mode: forwards;
    min-width: 10px;
}
.text-animate:nth-child(1){animation-delay: 0.2s;}
.text-animate:nth-child(2){animation-delay: 0.3s;}
.text-animate:nth-child(3){animation-delay: 0.4s;}
.text-animate:nth-child(4){animation-delay: 0.5s;}
.text-animate:nth-child(5){animation-delay: 0.6s;}
.text-animate:nth-child(6){animation-delay: 0.7s;}
.text-animate:nth-child(7){animation-delay: 0.8s;}
.text-animate:nth-child(8){animation-delay: 0.9s;}
.text-animate:nth-child(9){animation-delay: 1s;}
.text-animate:nth-child(10){animation-delay: 1.1s;}
.text-animate:nth-child(11){animation-delay: 1.2s;}
.text-animate:nth-child(12){animation-delay: 1.3s;}
.text-animate:nth-child(13){animation-delay: 1.4s;}
.text-animate:nth-child(14){animation-delay: 1.5s;}
.text-animate:nth-child(15){animation-delay: 1.6s;}
.text-animate:nth-child(16){animation-delay: 1.7s;}
.text-animate:nth-child(17){animation-delay: 1.8s;}
.text-animate:nth-child(18){animation-delay: 1.9s;}
.text-animate:nth-child(19){animation-delay: 2s;}
.text-animate:nth-child(20){animation-delay: 2.1s;}
.text-animate:nth-child(21){animation-delay: 2.2s;}
.text-animate:nth-child(22){animation-delay: 2.3s;}
.text-animate:nth-child(23){animation-delay: 2.4s;}
.text-animate:nth-child(24){animation-delay: 2.5s;}
.text-animate:nth-child(25){animation-delay: 2.6s;}
.text-animate:nth-child(26){animation-delay: 2.7s;}
.text-animate:nth-child(27){animation-delay: 2.8s;}
.text-animate:nth-child(28){animation-delay: 2.9s;}
.text-animate:nth-child(29){animation-delay: 3s;}


.text-animate-hover{
    min-width: 10px;
    display: inline-block;
    animation-fill-mode: both;
}
    .text-animate-hover:hover{
            animation: rubberBand 1s;
            color: var(--primary-blue);
        }



/*  
 @for $i from 1 through 38{   
      .text-animate._#{$i}{ 
                animation-delay: #{$i / 10}s;
  }
 }   */