
.navigation{
    height: 10vh;
    width: 100%;
    /* background-color: #f0f0f0; */
    box-shadow: 8px -8px 32px #bcbcbc,
    -8px 8px 32px #ffffff;;
    background-color: var(--backgound-color);
    display: flex;
    align-items: center;
    justify-content: space-evenly;

}

.DropDownMenu ul{
    list-style-type: none;
}
.DropDownMenu ul li{
    display: inline-block;
    position: relative;
    cursor: pointer;
}
.DropDownMenu ul li a{
    display: block;
    font-size: 18px;
    color: #333;
    text-decoration: none;
    text-align: left;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.DropDownMenu ul li a:hover{
    color: var(--primary-blue);
}
.DropDownMenu ul li a::before{
    content: "";
    width: 0%;
    height: 2px;
    border-radius: 5px;
    position: absolute;
    background-color: var(--primary-pink);
    bottom: 0;
    transition: all 0.4s ease;
}
.DropDownMenu ul li a:hover::before{
    width: 90%;
}
.DropDownMenu ul li ul.DropDownList li{
    padding: 1.2rem 2.4rem;
    display: block; 

    /* background-color: #323232; */
}
.DropDownMenu ul li ul.DropDownList{
    width: auto;
    background-color: #eee;
    margin-top: 5px;
    position: absolute;
    z-index: 99999;
    padding: 0px 0px 0px 0px;
    display: none;
    /* transform: translateY(-60vh); */
    transition: all 0.8s ease-in-out;
}
.DropDownMenu ul li h3{
    font-size: 1.2rem;
    cursor: pointer;
    font-weight: 500;
}
.DropDownMenu ul li h3 .FontAwesomeBro{
    font-size: 1.05rem;
    transition: all 0.4s ease-in-out;
    /* rotate: 90deg; */
}
.DropDownMenu:hover .DropDownList{  
    display: block;
  
}
.DropDownMenu:hover .FontAwesomeBro{
    transition: all 0.4s ease;
    animation: BroTurnDown 0.3s linear forwards ;
    color: var(--primary-red);
}

.active{
    color: var(--primary-blue);

}
@keyframes BroTurnDown {
    from{
        scale: 1;
        rotate: 0deg;
    }
    to{
        scale: 1.09;
        rotate: 90deg;
    }
}

.HabsLogo img{
    height: 65px;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
}
.HabsLogo img:hover{
    scale: 1.1;
    rotate: 360deg;
}

.NavHeading h1{
    font-size: 2rem;
    transition: all 0.4s ease-in-out;
}
.NavHeading h1 a{
    text-decoration: none;
    transition: all 0.4s ease-in-out;
    color: #333;
}
.NavHeading h1 a .span1{
    transition: all 0.4s ease-in-out;
    color: #333;
}
.NavHeading h1 a .span1 span{
    transition: all 0.4s ease-in-out;
    color: var(--primary-blue);
}
.NavHeading h1 a .span2{
    transition: all 0.4s ease-in-out;
    color: #333;
}
.NavHeading h1 a .span2 span{
    color: var(--primary-red);
}
.NavHeading h1 a:hover .span1 span{
    color: #333;
}
.NavHeading h1 a:hover .span1{
    color: var(--primary-blue);
}
.NavHeading h1 a:hover .span2 span{
    color: #333;
}
.NavHeading h1 a:hover .span2{
    color: var(--primary-red);
}
.NavHeading h1:hover{
    scale: 1.1;
}

@media only screen and (max-width: 1080px){

.NavHeading h1{
    font-size: 1.8rem;
}
.DropDownMenu{
    margin-left: -12%;
}
.HabsLogo{
    margin-left: -12%;

}

.DropDownMenu ul li a{
    font-size: 16px;
}

.DropDownMenu ul li ul.DropDownList li{
    padding: 1rem 2rem;

}

}

@media only screen and (max-width: 600px){
.HabsLogo{
    display: none;
}
/* .navigation{
    justify-content: space-around;
} */
    .HabsLogo img{
    height: 58px;
}
.NavHeading h1{
    font-size: 1.5rem;
}
.NavHeading{
    /* display: none; */
    margin-left: -14%;
}
.DropDownMenu ul li ul.DropDownList li{
    padding: 1rem 1.7rem;

}
}